<template>
	<v-container fluid class="px-10">
		<v-row>
			<v-col cols="12">
				<h1 color="white">Rankings</h1>
				<v-divider class="mt-5"></v-divider>
			</v-col>

			<v-col cols="12">
				<v-card flat tile :loading="loadingRanks">
					<v-tabs background-color="#192335" color="#fff" v-model="tab" align-with-title>
						<v-tabs-slider color="primary"></v-tabs-slider>
						<v-tab v-for="streamer in ranks" :key="streamer.nickname">
							{{ streamer.nickname }}
						</v-tab>
					</v-tabs>
				</v-card>
			</v-col>

			<v-col>
				<v-tabs-items v-model="tab">
					<v-tab-item v-for="(streamer, i) in ranks" :key="i">
						<v-col cols="12">
							<h3>Personal Ranks</h3>
						</v-col>

						<v-col cols="12">
							<v-row justify="space-between">
								<v-col cols="4">
									<h3>Your points ranking: #{{ streamer.user_rank_points }}</h3>
								</v-col>
								<v-col cols="4">
									<h3>Your chats sent ranking: #{{ streamer.user_rank_chats }}</h3>
								</v-col>
								<v-col cols="4">
									<h3>Your watchtime ranking: #{{ streamer.user_rank_wts }}</h3>
								</v-col>
							</v-row>
						</v-col>

						<v-divider class="my-5"></v-divider>

						<v-col cols="12">
							<v-row justify="space-between">

								<v-col cols="4">
									<h3>Top {{ streamer.top_points.length }} - Points</h3>
									<v-card flat tile>
										<v-simple-table>
											<template v-slot:default>
												<thead>
													<tr>
														<th class="text-left">Rank</th>
														<th class="text-left">User</th>
														<th class="text-left mgn_desc">Points</th>
													</tr>
												</thead>
												
												<tbody>
													<tr v-for="(usr, i) in streamer.top_points" :key="i">
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">{{ ++i }}</td>
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">{{ usr.nickname }}</td>
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">{{ usr.points }}</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</v-col>

								<v-divider vertical></v-divider>

								<v-col cols="4">
									<h3>Top {{ streamer.top_chats.length }} - Chats</h3>
									<v-card flat tile>	
										<v-simple-table>
											<template v-slot:default>
												<thead>
													<tr>
														<th class="text-left">Rank</th>
														<th class="text-left">User</th>
														<th class="text-left">Chats</th>
													</tr>
												</thead>
												
												<tbody>
													<tr v-for="(usr, i) in streamer.top_chats" :key="i">
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">{{ ++i }}</td>
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">{{ usr.nickname }}</td>
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">{{ usr.chats }}</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</v-col>

								<v-divider vertical></v-divider>

								<v-col cols="4">
									<h3>Top {{ streamer.top_watchtime.length }} - Watchtime</h3>
									<v-card flat tile>	
										<v-simple-table>
											<template v-slot:default>
												<thead>
													<tr>
														<th class="text-left">Rank</th>
														<th class="text-left">User</th>
														<th class="text-left">Watchtime</th>
													</tr>
												</thead>
												
												<tbody>
													<tr v-for="(usr, i) in streamer.top_watchtime" :key="i">
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">{{ ++i }}</td>
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">{{ usr.nickname }}</td>
														<td v-bind:class="{ 'mgn_highlight_user': usr.nickname === $store.state.user.nickname}">
															{{ 
																Math.floor(usr.time / 86400) + ' days ' +
																Math.floor(usr.time % 86400 / 3600) + ' hours ' +
																Math.floor(usr.time % 3600 / 60) + ' min '
															}}
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</v-col>

							</v-row>
						</v-col>
						
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			tab: 0,
			loadingRanks: false,
			ranks: [],
			data: [],
		}
	},

	async mounted() {
		this.loadingRanks = true;
		try {
			const request = await fetch(`${this.$store.state.base_url}/channel/rankings`, {
				method: 'GET',
				headers: {
					"Authorization" : this.$cookies.get('guardian_key_session'),
				}
			});
			const res = await request.json();

			if (request.ok && request.status === 200) {
				if (res.ranks) {
					this.data = res.ranks;
					this.ranks = this.data[0].data;
				}
			}
			
			if (request.status == 500 || request.status == 401) {
				if (res.error && this.$store.state.error_types.includes(res.error)) {
					this.sessionExpiredHandler(res);
					return;
				}

				throw (res);
			}
		} 
		catch (error) {
			this.$notify({
				group: 'main',
				type: 'error',
				title: this.$store.state.app_name,
				text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
			});
		}
		finally {
			this.loadingRanks = false;
		}
	},

	methods: {
		LoadData(platform) {
			this.loadingRanks = true;
			this.ranks = [];
			let a = this.data.find(u => u.platform === platform);
			this.ranks = a.data
			this.loadingRanks = false;
		}
	},
}
</script>

<style scoped>
.mgn_highlight_user {
	background-color: #00ff7b8c !important;
}
</style>